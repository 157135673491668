<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @before-close="handleBeforeClose"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <!-- <el-form-item label="活动名称" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item> -->
      <el-form-item
        label="工作类别"
        :label-width="formLabelWidth"
        prop="workType"
      >
        <el-select
          v-model="form.workType"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option label="Contract" value="1"></el-option>
          <el-option label="Permanent" value="2"></el-option>
          <el-option label="Temporary" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="职位类别"
        :label-width="formLabelWidth"
        prop="jobCategory"
      >
        <el-select
          v-model="form.jobCategory"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in categoryMap"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
          <!-- <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option> -->
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button
        type="primary"
        @click="handleConfirm('ruleForm')"
        :loading="confirmLoading"
      >
        确认添加
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getJobCategory, addJob, updateJob } from "@/js/service/TsdnService.js";

export default {
  name: "",
  components: {},
  data() {
    return {
      title: "添加职位 [职位名称] 到TSDN",
      dialogFormVisible: false,
      form: {
        workType: "",
        jobCategory: "",
      },
      rules: {
        workType: [
          { required: true, message: "请选择工作类型", trigger: "blur" },
        ],
        jobCategory: [
          { required: true, message: "请选择职位类别", trigger: "blur" },
        ],
      },
      formLabelWidth: "120px",
      categoryMap: [],
      confirmLoading: false,
      currentJob: null,
      currentType: "add",
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.currentType == "add") {
            this.createJob();
          } else {
            this.editJob();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      //   this.dialogFormVisible = false;
    },

    handleBeforeClose() {
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },

    editJob() {
      const params = {
        JobId: this.currentJob.jobId,
        WorkType: +this.form.workType,
        CategoryId: this.form.jobCategory,
      };

      this.confirmLoading = true;
      updateJob(params)
        .then((res) => {
          shortTips(
            `【${
              this.currentJob.jobName.length > 15
                ? this.currentJob.jobName.substr(0, 15) + "..."
                : this.currentJob.jobName
            }】修改成功`
          );

          this.dialogFormVisible = false;
          this.$emit("confirm-cb", "edit");
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },

    createJob() {
      const params = {
        JobId: this.currentJob.jobId,
        WorkType: +this.form.workType,
        CategoryId: this.form.jobCategory,
      };

      this.confirmLoading = true;
      addJob(params)
        .then((res) => {
          shortTips(
            `【${
              this.currentJob.jobName.length > 15
                ? this.currentJob.jobName.substr(0, 15) + "..."
                : this.currentJob.jobName
            }】添加成功`
          );

          this.dialogFormVisible = false;
          this.$emit("confirm-cb", "add", this.currentJob);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },

    show(it, type = "add") {
      this.dialogFormVisible = true;
      this.fetchJobCategory();
      this.currentJob = it;
      this.currentType = type;

      if (type == "add") {
        this.title = `添加职位 【${
          it.jobName.length > 15 ? it.jobName.substr(0, 15) + "..." : it.jobName
        }】 到TSDN`;

        this.form.workType = "";
        this.form.jobCategory = "";
      } else {
        this.title = `编辑职位 【${
          it.jobName.length > 15 ? it.jobName.substr(0, 15) + "..." : it.jobName
        }】`;

        this.form.workType = String(it.workType);
        this.form.jobCategory = it.categoryId;
      }
    },

    fetchJobCategory() {
      getJobCategory().then((res) => {
        this.categoryMap = res.data.data.list;
      });
    },

    handleCancel() {
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="scss" scope></style>

<template>
  <el-dialog
    title="请选择需要添加的职位"
    :visible.sync="dialogTableVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="800px"
  >
    <el-row>
      <el-col :span="12">
        <el-input
          v-model="keyWord"
          placeholder="请输入职位名称关键字"
          @keyup.native.enter="handleSearch"
          clearable
          @clear="handleSearch"
        ></el-input>
      </el-col>
    </el-row>
    <!-- max-height="400px" -->
    <el-table
      :data="gridData"
      border
      style="width: 100%; margin-top: 10px"
      v-loading="loading"
    >
      <el-table-column prop="jobName" label="职位名称" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-link type="primary" @click="handleJobDetail(row)">
            {{ row.jobName }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="customerName"
        label="公司名称"
        width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="updated"
        label="发布时间"
        width="200"
      ></el-table-column>
      <el-table-column prop="address" label="操作" width="100">
        <template slot-scope="{ row }">
          <el-link type="warning" v-if="row?.status == 'add'" disabled>
            已添加
          </el-link>
          <el-link type="primary" v-else @click="handleItAdd(row)">
            添加
          </el-link>
          <!-- <el-divider direction="vertical"></el-divider> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <el-pagination
        :current-page="pager.current"
        :page-size="pager.size"
        class="el-pagination-workTable"
        layout="total, prev, pager, next"
        :total="pager.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { getUnbindList } from "#/js/service/TsdnService.js";

export default {
  name: "",
  components: {},
  data() {
    return {
      keyWord: "",
      pager: {
        current: 1,
        size: 10,
        total: 0,
      },
      gridData: [],
      dialogTableVisible: false,
      loading: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    show() {
      this.dialogTableVisible = true;
      this.pager.current = 1;
      this.getTableData();
    },
    getTableData() {
      const params = {
        jobName: this.keyWord,
        start: (this.pager.current - 1) * this.pager.size,
        take: this.pager.size,
      };
      this.loading = true;
      getUnbindList(params)
        .then((res) => {
          this.gridData = res.list || [];
          this.pager.total = res.total || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    changeTableStatus(type, obj) {
      console.log("🚀 ~ changeTableStatus ~ type, obj:", type, obj);
      this.gridData = this.gridData.map((it) => {
        if (it.jobId == obj.jobId) {
          it.status = type;
        }
        return it;
      });
      //   this.$forceUpdate();
      console.log("🚀 ~ this.gridData.forEach ~ this.gridData:", this.gridData);
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pager.current = val;
      this.getTableData();
    },

    handleSearch() {
      this.pager.current = 1;
      this.getTableData();
    },

    handleItAdd(row) {
      this.$emit("add-cb", row);
      //   this.dialogTableVisible = false;
    },

    handleJobDetail(row) {
      window.open(`/Headhunting/MyCompany.html#/Job/${row.jobId}`);
    },
  },
};
</script>

<style lang="scss" scope>
.pager {
  text-align: center;
  margin-top: 20px;
}
</style>

import {
    firmScaleMap,
    firmTypeMap,
    applicationStatusMap,
    activitiesApplicationStatusMap,
    joinStepsMap,
    talentLevelMap,
    degreeMap,
    talentStatusMap,
    gendersMap,
    salaryMap,
    minYearOfExperience,

    workTypeMap,
    thirdStatusMap,
} from './constantConfig.js';
// import {
//   industryOptions,
// } from '../config/industry-data.js';
import {
    findLabelByValue,
} from './transform.js';
import { province as provinceTree } from '../provinceThreeData.json';
// import {
//   majorsMap,
// } from './major-data.js';
import moment from 'moment';

export const getLabelByValue = (val, type) => {
    let str = '';
    switch (type) {
        case 'identityType':
            str = firmTypeMap.find(it => it.value == val)?.label;
            break;
        case 'companyScale':
            str = firmScaleMap.find(it => it.value == val)?.label;
            break;
        // case 'industryId':
        //   str = industryOptions.find(it => it.id == val)?.name;
        //   break;
        case 'applicationStatus':
            str = applicationStatusMap.find(it => it.value == val)?.label;
            break;
        case 'activitiesApplicationStatus':
            str = activitiesApplicationStatusMap.find(it => it.value == val)?.label;
            break;
        case 'joinSteps':
            str = joinStepsMap.find(it => it.value == val)?.label;
            break;
        case 'talentLevel':
            str = talentLevelMap.find(it => it.value == val)?.label;
            break;
        case 'degree':
            str = degreeMap.find(it => it.value == val)?.label;
            break;
        case 'talentStatus':
            str = talentStatusMap.find(it => it.value == val)?.label;
            break;
        case 'minYearOfExperience':
            str = minYearOfExperience[val];
            break;
        case 'locationId':
            str = findLabelByValue(provinceTree, val);
            break;
        // case 'major':
        //   str = majorsMap.find(it => it.id == val)?.name;
        //   break;
        case 'gender':
            str = gendersMap.find(it => it.value == val)?.label;
            break;
        case 'salary':
            str = salaryMap.find(it => it.value == val)?.label;
            break;
        case 'workType':
            str = workTypeMap.find(it => it.value == val)?.label;
            break;
        case 'thridStatus':
            str = thirdStatusMap.find(it => it.value == val)?.label;
            break;

        default:
            break;
    }
    return str || '未知';
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
    if (!date) return '';
    return moment(date).format(format);
};

export const getGapYears = (startDate, endDate) => {
    if (!endDate) endDate = new Date();
    return moment(endDate).diff(moment(startDate), 'year');
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        "before-close": _vm.handleBeforeClose,
      },
    },
    [
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "工作类别",
                "label-width": _vm.formLabelWidth,
                prop: "workType",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.workType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "workType", $$v)
                    },
                    expression: "form.workType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Contract", value: "1" } }),
                  _c("el-option", {
                    attrs: { label: "Permanent", value: "2" },
                  }),
                  _c("el-option", {
                    attrs: { label: "Temporary", value: "3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "职位类别",
                "label-width": _vm.formLabelWidth,
                prop: "jobCategory",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.jobCategory,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "jobCategory", $$v)
                    },
                    expression: "form.jobCategory",
                  },
                },
                _vm._l(_vm.categoryMap, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: {
                click: function ($event) {
                  return _vm.handleConfirm("ruleForm")
                },
              },
            },
            [_vm._v("\n      确认添加\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
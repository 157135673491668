var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c("el-tab-pane", {
              attrs: { label: "TSDN发布职位", name: "first" },
            }),
            _c("el-tab-pane", {
              attrs: { label: "TSDN投递管理", name: "second" },
            }),
          ],
          1
        ),
        _vm.activeName == "first"
          ? _c(
              "div",
              { staticClass: "blank" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入职位名称",
                            clearable: "",
                          },
                          on: { clear: _vm.handleSearch },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.keyWord,
                            callback: function ($$v) {
                              _vm.keyWord = $$v
                            },
                            expression: "keyWord",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择", clearable: "" },
                            on: {
                              change: _vm.handleSearch,
                              clear: _vm.handleSearch,
                            },
                            model: {
                              value: _vm.status,
                              callback: function ($$v) {
                                _vm.status = $$v
                              },
                              expression: "status",
                            },
                          },
                          _vm._l(_vm.jobStatusMap, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { span: 4, offset: 8 },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handAdd },
                          },
                          [_vm._v(" 添加职位 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%", "margin-top": "10px" },
                    attrs: { data: _vm.publishedList, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "jobName",
                        label: "职位名称",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleJobDetail(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row?.jobName) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2974310142
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerName",
                        label: "企业名称",
                        width: "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "经验要求",
                        width: "120",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabelByValue(
                                        row.minYearOfExperience,
                                        "minYearOfExperience"
                                      )
                                    ) + "\n            "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1026244442
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "学历要求",
                        width: "120",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.getLabelByValue(
                                        row.minDegree,
                                        "degree"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        442184248
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "地点",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.locationText) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3090040255
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "职位类别",
                        width: "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.computedCategory(row.categoryId)
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        149720258
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "workType",
                        label: "工作类型",
                        width: "150",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.getLabelByValue(
                                        row.workType,
                                        "workType"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3646413298
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "deliveryCount",
                        label: "投递数",
                        width: "80",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.deliveryCount) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        957208736
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "status", label: "状态", width: "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.getJobStatusText(row.jobStatus)
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1300975509
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "address", label: "操作", width: "180" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleJobEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "warning" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleJobRemove(row)
                                      },
                                    },
                                  },
                                  [_vm._v("\n              移除\n            ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2402049817
                      ),
                    }),
                  ],
                  1
                ),
                _vm.pager.total > 0
                  ? _c(
                      "div",
                      { staticClass: "pager" },
                      [
                        _c("el-pagination", {
                          staticClass: "el-pagination-workTable",
                          attrs: {
                            "current-page": _vm.pager.current,
                            "page-size": _vm.pager.size,
                            layout: "total, prev, pager, next, sizes",
                            "page-sizes": [10, 20, 30, 50],
                            total: _vm.pager.total,
                          },
                          on: {
                            "current-change": (val) =>
                              _vm.handleCurrentChange(val, "pager1"),
                            "size-change": (val) =>
                              _vm.handleSizeChange(val, "pager1"),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.activeName == "second"
          ? _c(
              "div",
              { staticClass: "blank" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "职位名称", clearable: "" },
                          on: { clear: _vm.handleDeliverSearch },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleDeliverSearch.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.JobName,
                            callback: function ($$v) {
                              _vm.JobName = $$v
                            },
                            expression: "JobName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "姓名", clearable: "" },
                          on: { clear: _vm.handleDeliverSearch },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleDeliverSearch.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.UserName,
                            callback: function ($$v) {
                              _vm.UserName = $$v
                            },
                            expression: "UserName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "电话", clearable: "" },
                          on: { clear: _vm.handleDeliverSearch },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleDeliverSearch.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.Mobile,
                            callback: function ($$v) {
                              _vm.Mobile = $$v
                            },
                            expression: "Mobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "邮箱", clearable: "" },
                          on: { clear: _vm.handleDeliverSearch },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleDeliverSearch.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.Email,
                            callback: function ($$v) {
                              _vm.Email = $$v
                            },
                            expression: "Email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%", "margin-top": "10px" },
                    attrs: { data: _vm.deliveryList, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "jobName",
                        label: "职位名称",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleJobDetail(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.jobName) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2990861601
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "投递人姓名",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.firstName) +
                                    " " +
                                    _vm._s(row.lastName) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        716827622
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mobile",
                        label: "投递人联电话",
                        width: "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "email",
                        label: "投递人联系邮箱",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "简历附件",
                        width: "260",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      title: "点击下载简历",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownloadResume(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.resumeName) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3567903552
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "推荐信附件",
                        width: "260",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      title: "点击下载推荐信",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownloadRecommendationLetter(
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(row.recommendationLetterName) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2511641983
                      ),
                    }),
                  ],
                  1
                ),
                _vm.pager2.total > 0
                  ? _c(
                      "div",
                      { staticClass: "pager" },
                      [
                        _c("el-pagination", {
                          staticClass: "el-pagination-workTable",
                          attrs: {
                            "current-page": _vm.pager2.current,
                            "page-size": _vm.pager2.size,
                            layout: "total, prev, pager, next, sizes",
                            "page-sizes": [10, 20, 30, 50],
                            total: _vm.pager2.total,
                          },
                          on: {
                            "current-change": (val) =>
                              _vm.handleCurrentChange(val, "pager2"),
                            "size-change": (val) =>
                              _vm.handleSizeChange(val, "pager2"),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c("add-job-dialog", {
          ref: "addJobDialog",
          on: { "add-cb": _vm.handleAddCB },
        }),
        _c("edit-job-dialog", {
          ref: "editJobDialog",
          on: { "confirm-cb": _vm.handleConfirmCB },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// export const hireType = ['猎头招聘', '外包招聘'];
export const hireType = {
    "1": "猎头招聘",
    "0": "外包招聘",
};

export const minYearOfExperience = {
    "0": "不要求",
    "1": "1-3年",
    "3": "3-5年",
    "5": "5-10年",
    "10": "10年以上",
};

export const minDegree = {
    "0": "不要求",
    "1": "高中及以下",
    "2": "大专",
    "3": "本科",
    "4": "硕士",
    "5": "MBA",
    "6": "博士"
};

// export const status = ['招聘中', '已暂停'];
export const status = {
    "1": "招聘中",
    "2": "已暂停",
    "4": "已下线",
};


// export const guaranteePeriod = ['6个月', '3个月', '无'];
export const guaranteePeriod = {
    "6": "6个月",
    "3": "3个月",
    "0": "无",
};

// export const brokerageMap = ['按比例', '固定佣金'];
export const brokerageMap = {
    "0": "按比例",
    "1": "固定佣金",
};

export const firmScaleMap = [
    { label: '未设置', value: '0' },
    { label: '少于50人', value: '1' },
    { label: '50-150人', value: '2' },
    { label: '150-500人', value: '3' },
    { label: '500-1000人', value: '4' },
    { label: '1000人以上', value: '5' },
];

export const firmTypeMap = [
    { label: '国企', value: '0' },
    { label: '民营', value: '1' },
    { label: '外资', value: '2' },
    { label: '合资', value: '3' },
];

export const applicationStatusMap = [
    { label: '待完善', value: '0' },
    { label: '待认证', value: '1' },
    { label: '已通过', value: '2' },
    { label: '已拒绝', value: '3' },
    { label: '已取消', value: '4' },
];

export const activitiesApplicationStatusMap = [
    { label: '活动被关闭', value: '0' },
    { label: '待发布', value: '1' },
    { label: '审核中', value: '2' },
    { label: '审核不通过', value: '3' },
    { label: '发布中', value: '4' },
    { label: '取消发布', value: '5' },
    { label: '已结束', value: '6' },
];

export const joinStepsMap = [
    // { label: '已加入', value: '4' },
    { label: '公司信息待完善', value: '1' },
    { label: '创建公司审核中', value: '2' },
    { label: '加入公司审核中', value: '3' },
    { label: '已加入', value: '4' },
    { label: '认证未通过', value: '5' },
    { label: '认证未通过', value: '6' },
    { label: '认证通过', value: '7' },
];

export const gendersMap = [
    { label: '未知', value: '0' },
    { label: '男', value: '1' },
    { label: '女', value: '2' },
];

export const degreeMap = [
    { "label": "学历不限", "value": 0 },
    { "label": "高中及以下", "value": 1 },
    { "label": "大专", "value": 2 },
    { "label": "本科", "value": 3 },
    { "label": "硕士", "value": 4 },
    { "label": "MBA", "value": 5 },
    { "label": "博士", "value": 6 },
    { "label": "博士及以上", "value": 7 },
];

export const salaryMap = [
    // { value: "", label: "不限" },
    { value: "0-10", label: "10W以下" },
    { value: "10-30", label: "10W-30W" },
    { value: "30-50", label: "30W-50W" },
    { value: "50-80", label: "50W-80W" },
    { value: "80-100", label: "80W-100W" },
    { value: "100-300", label: "100W-300W" },
    { value: "300-500", label: "300W-500W" },
    { value: "500-800", label: "500W-800W" },
    { value: "800-1000", label: "800W-1000W" },
    { value: "1000-", label: "1000W以上" },
];

export const talentLevelMap = [
    // { value: "", label: "未知" },
    { value: 1, label: "A级" },
    { value: 2, label: "B级" },
    { value: 3, label: "C级" },
];

export const talentStatusMap = [
    { label: '未认证', value: '1' },
    { label: '已认证', value: '2' },
];

export const activitiesJobStatusMap = [
    { label: '待发布', value: '0' },
    { label: '待审核', value: '1' },
    { label: '发布中', value: '2' },
    { label: '审核未通过', value: '3' },
    { label: '取消发布', value: '4' },
    { label: '关闭', value: '5' },
];


export const thirdStatusMap = [
    { label: '已发布', value: '9' },
    { label: '源职位已暂停', value: '11' },
    { label: '源职位已完成', value: '13' },
];

export const workTypeMap = [
    { label: 'Contract', value: '1' },
    { label: 'Permanent', value: '2' },
    { label: 'Temporary', value: '3' },
];



export function findLabelByValue (tree, targetValue) {
  // 遍历当前节点的所有子节点  
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    // 检查当前节点的value是否匹配目标值  
    if (node.value == targetValue) {
      // 如果匹配，返回当前节点的label  
      return node.label;
    }

    // 如果当前节点有子节点，递归调用findLabelByValue  
    if (node.children && node.children.length > 0) {
      const foundLabel = findLabelByValue(node.children, targetValue);
      if (foundLabel) {
        // 如果在子节点中找到匹配项，返回找到的label  
        return foundLabel;
      }
    }
  }

  // 如果没有找到匹配项，返回null  
  return null;
}  
import axios from 'axios';
import { Tsdn } from '#/js/config/api.json';


export const getUnbindList = params => {
    return _request({
        url: Tsdn.get_unbind_list,
        method: "POST",
        baseURL: "LbdOpenApi",
        data: params
    });
};

export const addJob = params => {
    return _request({
        url: Tsdn.add_job,
        method: "POST",
        baseURL: "LbdOpenApi",
        data: params
    });
};


export const updateJob = params => {
    return _request({
        url: Tsdn.update_job,
        method: "POST",
        baseURL: "LbdOpenApi",
        data: params
    });
};

export const getDeliveryList = params => {
    return _request({
        url: Tsdn.delivery_list,
        method: "POST",
        baseURL: "LbdOpenApi",
        data: params
    });
};

export const getPublishedList = params => {
    return _request({
        url: Tsdn.published_list,
        method: "POST",
        baseURL: "LbdOpenApi",
        data: params
    });
};

export const jobDelete = jobId => {
    return _request({
        url: Tsdn.job_remove.replace(/%p/, jobId),
        method: "POST",
        baseURL: "LbdOpenApi",
    });
};

const tsdnUrlMap = {
    "rc": "https://operationapi.rc.tsdn.global",
    "staging": "https://operationapi.stg.tsdn.global",
    "production": "https://operationapi.tsdn.global",
};
const tsdnUrl = tsdnUrlMap[NODE_ENV];

export const getJobCategory = params => {
    return axios({
        method: 'GET',
        url: `${tsdnUrl}/system/category/tree?type=1`,
    });
};

export const downloadFile = params => {
    return axios({
        url: `${tsdnUrl}/deliver/download-file`,
        method: "POST",
        data: params,
        responseType: 'blob', // 设置响应类型为blob
    });
};
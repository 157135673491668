<template>
  <div class="container">
    <div class="wrap">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="TSDN发布职位" name="first"></el-tab-pane>
        <el-tab-pane label="TSDN投递管理" name="second"></el-tab-pane>
      </el-tabs>

      <div class="blank" v-if="activeName == 'first'">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input
              v-model="keyWord"
              placeholder="请输入职位名称"
              clearable
              @clear="handleSearch"
              @keyup.native.enter="handleSearch"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-select
              v-model="status"
              placeholder="请选择"
              style="width: 100%"
              @change="handleSearch"
              clearable
              @clear="handleSearch"
            >
              <el-option
                v-for="item in jobStatusMap"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" :offset="8" style="text-align: right">
            <el-button @click="handAdd" type="primary"> 添加职位 </el-button>
            <!-- <el-button @click="handtt" type="primary">tt</el-button> -->
          </el-col>
        </el-row>

        <el-table
          :data="publishedList"
          border
          style="width: 100%; margin-top: 10px"
          v-loading="loading"
        >
          <el-table-column
            prop="jobName"
            label="职位名称"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <el-link type="primary" @click="handleJobDetail(row)">
                {{ row?.jobName }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop="customerName" label="企业名称" width="180">
          </el-table-column>
          <el-table-column prop="address" label="经验要求" width="120">
            <template slot-scope="{ row }">
              <span
                >{{
                  getLabelByValue(
                    row.minYearOfExperience,
                    "minYearOfExperience"
                  )
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="学历要求" width="120">
            <template slot-scope="{ row }">
              {{ getLabelByValue(row.minDegree, "degree") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="地点"
            width="180"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <!-- {{ getLabelByValue(row.locationId, "locationId") }} -->
              {{ row.locationText }}
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="职位类别"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              {{ computedCategory(row.categoryId) }}
            </template>
          </el-table-column>
          <el-table-column prop="workType" label="工作类型" width="150">
            <template slot-scope="{ row }">
              {{ getLabelByValue(row.workType, "workType") }}
            </template>
          </el-table-column>
          <el-table-column prop="deliveryCount" label="投递数" width="80">
            <template slot-scope="{ row }">
              {{ row.deliveryCount }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="120">
            <template slot-scope="{ row }">
              <!-- {{ getLabelByValue(row.status, "thridStatus") }} -->
              {{ getJobStatusText(row.jobStatus) }}
            </template>
          </el-table-column>

          <el-table-column prop="address" label="操作" width="180">
            <template slot-scope="{ row }">
              <el-link type="primary" @click="handleJobEdit(row)">编辑</el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link type="warning" @click="handleJobRemove(row)">
                移除
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="pager" v-if="pager.total>0">
          <el-pagination
            :current-page="pager.current"
            :page-size="pager.size"
            class="el-pagination-workTable"
            layout="total, prev, pager, next, sizes"
            :page-sizes="[10, 20, 30, 50]"
            :total="pager.total"
            @current-change="(val) => handleCurrentChange(val, 'pager1')"
            @size-change="(val) => handleSizeChange(val, 'pager1')"
          >
          </el-pagination>
        </div>
      </div>

      <div class="blank" v-if="activeName == 'second'">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input
              v-model="JobName"
              placeholder="职位名称"
              clearable
              @clear="handleDeliverSearch"
              @keyup.native.enter="handleDeliverSearch"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="UserName"
              placeholder="姓名"
              clearable
              @clear="handleDeliverSearch"
              @keyup.native.enter="handleDeliverSearch"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="Mobile"
              placeholder="电话"
              clearable
              @clear="handleDeliverSearch"
              @keyup.native.enter="handleDeliverSearch"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="Email"
              placeholder="邮箱"
              clearable
              @clear="handleDeliverSearch"
              @keyup.native.enter="handleDeliverSearch"
            ></el-input>
          </el-col>
        </el-row>

        <!-- element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
          :cell-style="{ padding: '2px' }"
          :row-style="{ height: '20px' }"
          :default-sort="{ prop: 'date', order: 'descending' }" -->
        <el-table
          :data="deliveryList"
          border
          style="width: 100%; margin-top: 10px"
          v-loading="loading"
        >
          <el-table-column
            prop="jobName"
            label="职位名称"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <el-link type="primary" @click="handleJobDetail(row)">
                {{ row.jobName }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="投递人姓名"
            width="180"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              {{ row.firstName }} {{ row.lastName }}
            </template>
          </el-table-column>
          <el-table-column prop="mobile" label="投递人联电话" width="180">
          </el-table-column>
          <el-table-column
            prop="email"
            label="投递人联系邮箱"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="简历附件"
            width="260"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <el-link
                type="primary"
                @click="handleDownloadResume(row)"
                title="点击下载简历"
              >
                {{ row.resumeName }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="推荐信附件" width="260">
            <template slot-scope="{ row }">
              <el-link
                type="primary"
                @click="handleDownloadRecommendationLetter(row)"
                title="点击下载推荐信"
              >
                {{ row.recommendationLetterName }}
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="pager" v-if="pager2.total>0">
          <el-pagination
            :current-page="pager2.current"
            :page-size="pager2.size"
            class="el-pagination-workTable"
            layout="total, prev, pager, next, sizes"
            :page-sizes="[10, 20, 30, 50]"
            :total="pager2.total"
            @current-change="(val) => handleCurrentChange(val, 'pager2')"
            @size-change="(val) => handleSizeChange(val, 'pager2')"
          ></el-pagination>
        </div>
      </div>

      <add-job-dialog ref="addJobDialog" @add-cb="handleAddCB"></add-job-dialog>

      <edit-job-dialog
        ref="editJobDialog"
        @confirm-cb="handleConfirmCB"
      ></edit-job-dialog>
    </div>
  </div>
</template>

<script>
import AddJobDialog from "./components/add-job-dialog.vue";
import EditJobDialog from "./components/edit-job-dialog.vue";
import {
  addJob,
  getDeliveryList,
  getPublishedList,
  jobDelete,
  getJobCategory,
  downloadFile,
} from "@/js/service/TsdnService.js";
import { getLabelByValue } from "@/js/config/dates/format.js";

export default {
  name: "",
  components: {
    AddJobDialog,
    EditJobDialog,
  },
  data() {
    return {
      activeName: "first",
      keyWord: "",
      options: [
        {
          value: "9",
          label: "发布中",
        },
        {
          value: "11",
          label: "源职位已暂停",
        },
        {
          value: "13",
          label: "源职位已完成",
        },
      ],

      jobStatusMap: [
        {
          value: "1",
          label: "招聘中",
        },
        {
          value: "2",
          label: "已暂停",
        },
        {
          value: "3",
          label: "沉睡中",
        },
        {
          value: "4",
          label: "已完成",
        },
        // {
        //   value: "10",
        //   label: "已产生推荐",
        // },
        // {
        //   value: "11",
        //   label: "已安排面试",
        // },
        // {
        //   value: "12",
        //   label: "沉睡职位",
        // },
      ],
      status: "",

      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普",
        },
      ],

      currentJob: null,
      publishedList: [],
      pager: {
        current: 1,
        size: 10,
        total: 0,
      },

      JobName: "",
      UserName: "",
      Mobile: "",
      Email: "",
      deliveryList: [],
      pager2: {
        current: 1,
        size: 10,
        total: 0,
      },
      loading: false,

      categoryMap: [],
    };
  },
  created() {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    isInternalControl() {
      return this.$store.state.user.userInfo.roleCodeList?.includes(
        "InternalControl"
      );
    },
  },
  mounted() {
    this.fetchJobCategory();
  },

  methods: {
    computedCategory(id) {
      return this.categoryMap.find((item) => item.id == id)?.name || "未知";
    },
    fetchJobCategory() {
      getJobCategory().then((res) => {
        this.categoryMap = res.data.data.list;
        this.fetchPublishedList();
      });
    },
    handleSearch() {
      this.pager.current = 1;
      this.fetchPublishedList();
    },

    handleDeliverSearch() {
      this.pager2.current = 1;
      this.fetchDeliverList();
    },

    handleClick(tab, event) {
      const { index } = tab;
      if (index === "0") {
        this.fetchPublishedList();
      } else {
        this.fetchDeliverList();
      }
      console.log(this.deliveryList);
      console.log(this.publishedList);
    },

    handAdd() {
      this.$refs.addJobDialog.show();
    },

    handtt() {
      this.$refs.editJobDialog.show();
    },

    handleAddCB(row) {
      this.currentJob = row;
      this.$refs.editJobDialog.show(row);
    },

    handleConfirmCB(type, obj) {
      this.fetchPublishedList();
      //   this.$refs.addJobDialog.getTableData();
      if (type == "add") {
        this.$refs.addJobDialog.changeTableStatus(type, obj);
      }
    },

    fetchPublishedList() {
      const params = {
        JobName: this.keyWord,
        jobStatus: this.status == "" ? "" : +this.status,

        start: (this.pager.current - 1) * this.pager.size,
        take: this.pager.size,
      };

      this.loading = true;
      getPublishedList(params)
        .then((res) => {
          this.publishedList = res.list || [];
          this.pager.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchDeliverList() {
      const params = {
        JobName: this.JobName,
        UserName: this.UserName,
        Mobile: this.Mobile,
        Email: this.Email,

        start: (this.pager2.current - 1) * this.pager2.size,
        take: this.pager2.size,
      };

      this.loading = true;
      getDeliveryList(params)
        .then((res) => {
          this.deliveryList = res.list || [];
          this.pager2.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleJobEdit(row) {
      this.$refs.editJobDialog.show(row, "edit");
    },

    handleJobRemove(row) {
      this.$confirm(`从TSDN移除职位【${row.jobName}】吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          jobDelete(row.jobId).then((res) => {
            shortTips(`【${row.jobName}】职位移除成功`);
            this.fetchPublishedList();
          });
        })
        .catch(() => {});
    },

    handleCurrentChange(val, pager) {
      if (pager == "pager1") {
        this.pager.current = val;
        this.fetchPublishedList();
      } else {
        this.pager2.current = val;
        this.fetchDeliverList();
      }
    },

    handleSizeChange(val, pager) {
      if (pager == "pager1") {
        this.pager.size = val;
        this.pager.current = 1;
        this.fetchPublishedList();
      } else {
        this.pager2.size = val;
        this.pager2.current = 1;
        this.fetchDeliverList();
      }
    },

    getLabelByValue: getLabelByValue,

    handleJobDetail(row) {
      window.open(`/Headhunting/MyCompany.html#/Job/${row.jobId}`);
    },

    // 将文件流写入本地
    writeDownloadFile(blob, attachName) {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = attachName; // 下载文件的名字
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },

    getClientId() {
      if (NODE_ENV === "rc") {
        return "AC502DD2-1F29-11EF-B256-0C42A106C8D2";
      } else if (NODE_ENV === "staging") {
        return "EA2B14CF-1F37-11EF-B256-0C42A106C8D2";
      } else if (NODE_ENV === "production") {
        return "4F2245F5-1F38-11EF-B256-0C42A106C8D2";
      }
    },

    handleDownloadResume(row) {
      const params = {
        clientId: this.getClientId(),
        filePath: row.resumeFileUrl,
      };

      downloadFile(params).then((res) => {
        // let blob = new Blob([res.data.data.byteData]); // 假设response.data是从后端获取的二进制数据，类型可以根据实际情况设定
        this.writeDownloadFile(res.data, row.resumeName);
      });
    },

    handleDownloadRecommendationLetter(row) {
      const params = {
        clientId: this.getClientId(),
        filePath: row.recommendationLetterFileUrl,
      };

      downloadFile(params).then((res) => {
        this.writeDownloadFile(res.data, row.recommendationLetterName);
      });
    },

    getJobStatusText(jobStatus) {
      return (
        this.jobStatusMap.find((it) => it.value == jobStatus)?.label || "-"
      );
    },
  },
};
</script>

<style lang="scss" scope>
.container {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  .wrap {
    background: white;
    padding: 20px;
    min-height: calc(100vh - 160px);
    .blank {
      .pager {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
</style>
